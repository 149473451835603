export const result = {
    "A1": "Круто! Ваш уровень английского – А1. Вы уже можете поддержать базовый разговор на английском и рассказать о себе.",
    "A2": "Круто! Ваш уровень английского – А2. Вы уже способны пообщаться с носителем языка на будничные темы! Дальше-больше)",
    "A2_bad": "Хорошая работа! Но этот тест для вас немного сложноват. Скорее всего, ваш уровень английского ниже, чем средний. Попробуйте пройти более простой тест.",
    "B1_good": "Прекрасная работа! Этот тест был для вас слишком простым) Скорее всего, ваш уровень английского выше, чем начальный. Попробуйте пройти более сложный тест!",
    "B1": "Круто! Ваш уровень английского – В1. Этот уровень по-другому называется Independent – то есть независимый. Вы в состоянии непринужденно поболтать с другом или коллегой, поделиться жизненным опытом, рассказать про свои планы, доказать свою точку зрения, смотреть несложные фильмы и сериалы и развлекательные блоги на YouTube – столько всего!",
    "B2": "Круто! Ваш уровень английского – В2. Вы уже без пяти минут эксперт в английском языке! Вы понимаете беглую устную английскую речь, пользуетесь крылатыми фразами, а времена английского языка уже давно не вызывают у вас ужаса. На этом уровне уже можно начинать смотреть образовательные видео на YouTube по своей профессии, фильмы, слушать подкасты и читать книги на английском языке. Дальше-больше)",
    "B2_bad": "Хорошая работа! Но этот тест для вас немного сложноват. Скорее всего, ваш уровень английского ниже, чем продвинутый. Попробуйте пройти более простой тест.",
    "C1_good": "Прекрасная работа! Этот тест был для вас слишком простым) Скорее всего, ваш уровень английского выше, чем средний. Попробуйте пройти более сложный тест!",
    "C1": "Круто! Ваш уровень английского – С1. Вы в состоянии не просто понять устный или письменный текст, а еще и проанализировать его, найти смысл, скрытый между строк! Вы понимаете продвинутую лексику, пословицы и поговорки на английском языке, и можете использовать их в речи. Самое время начать читать литературу по профессии или смотреть обучающие видео на YouTube на английском языке!",
    "C2": "Круто! Ваш уровень английского – С1. Вы в состоянии не просто понять устный или письменный текст, а еще и проанализировать его, найти смысл, скрытый между строк! Вы разбираетесь в том, какие именно слова подобрать в конкретной ситуации. Вы понимаете продвинутую лексику, пословицы и поговорки на английском языке, и можете использовать их в речи. Самое время начать читать литературу по профессии или смотреть обучающие видео на YouTube на английском языке!",
    "defoult": "А чтобы улучшить свои навыки, поскорее записывайтесь на вводный урок в школе Unlock!",
}

export const dispointment = {
    "A-John": { "min": "5.8", "threshold-difficult": 5.4, "threshold-max": "25.2", l1: "A1", l2: "A1", l3: "A2", l4: "B1_good" }, /* all "difficult": 9 "max": "29"*/
    "B-John": { "min": "6.8", "threshold-difficult": 12, "threshold-max": "30.6", l1: "A2_bad", l2: "B1", l3: "B2", l4: "C1_good" }, /* all "difficult": 20 "max": "34"*/
    "C-John": { "min": "5.6", "threshold-difficult": 9.6, "threshold-max": "25.2", l1: "B2_bad", l2: "C1", l3: "C1", l4: "C2" }, /* all "difficult": 16 "max": "28 "*/
    "A-Lisa": { "min": "6", "threshold-difficult": 7.8, "threshold-max": "27", l1: "A1", l2: "A1", l3: "A2", l4: "B1_good" }, /* all "difficult": 13 "max": "30"*/
    "B-Lisa": { "min": "6.4", "threshold-difficult": 10.8, "threshold-max": "28.8", l1: "A2_bad", l2: "B1", l3: "B2", l4: "C1_good" }, /* all "difficult": 18 "max": "32"*/
    "C-Lisa": { "min": "4.4", "threshold-difficult": 10.2, "threshold-max": "19.8", l1: "B2_bad", l2: "C1", l3: "C1", l4: "C2" }, /* all "difficult": 17 "max": "22"*/
    "A-Stewart": { "min": "5.6", "threshold-difficult": 6.6, "threshold-max": "25.2", l1: "A1", l2: "A1", l3: "A2", l4: "B1_good" }, /* all "difficult": 11 "max": "28"*/
    "B-Stewart": { "min": "6.6", "threshold-difficult": 8.4, "threshold-max": "29.7", l1: "A2_bad", l2: "B1", l3: "B2", l4: "C1_good" }, /* all "difficult": 14 "max": "33"*/
    "C-Stewart": { "min": "4.4", "threshold-difficult": 10.2, "threshold-max": "19.8", l1: "B2_bad", l2: "C1", l3: "C1", l4: "C2" }, /* all "difficult": 17 "max": "22"*/
}