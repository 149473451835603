export const question = {
    "q1": "Как вы оцениваете свой уровень английского?",
    "q2": "Итак, вы на сайте для общения с иностранцами. Выберите себе собеседника:",
    "chat": "Давайте попробуем пообщаться в формате переписки с другом",
};

export const warning = {
    "q1": "Перед тем, как перейти к следующему этапу, выберите свой уровень английского языка",
    "q2": "Перед тем, как перейти к следующему этапу, выберите человека, с которым вы хотели бы пообщаться",
    "chat": "Перед отправкой выберите ответ",
}