export const levels = [
    /* { id: "A1", str: "A1 (Beginner)", wight: 5 },
    { id: "A2", str: "A2 (Elementary English)", wight: 7 },
    { id: "B1", str: "B1 (Intermediate English)", wight: 10 },
    { id: "B2", str: "B2 (Upper-Intermediate English)", wight: 12 },
    { id: "C1", str: "C1 (Advanced English)", wight: 15 },
    { id: "C2", str: "C2 (Proficiency English)", wight: 20 }, */
    { id: "A", level: "A1 - A2", str: "Начальный – могу заказать кофе или зарегистрироваться в приложении", wight: 5 },
    { id: "B", level: "B1 - B2", str: "Средний – могу рассказать, как провел лето", wight: 7 },
    { id: "C", level: "C1 - C2", str: "Продвинутый – могу доказать, почему смотреть сериалы на английском – это не пустая трата времени", wight: 10 },
];